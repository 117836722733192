@import '../styles/variables';

.checkbox {
  width: calc($spacing * 5) !important;
  height: calc($spacing * 5) !important;
  margin: 0 !important;

  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.form-control:disabled,
.btn:disabled {
  background-color: rgba($text, 0.15) !important;
  opacity: 1 !important;
}
