@import 'styles/index.scss';
@import '../node_modules/bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0;
  white-space: pre-wrap;
}

button {
  color: $text !important;
}

*::-webkit-scrollbar {
  width: calc($spacing * 1.5);
  height: 0;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: lighten($text, 65);
  border-radius: 10px;

  &:hover {
    background: lighten($text, 45);
  }
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: #{$dropdownLinkHover};
  --bs-dropdown-link-active-bg: #{$dropdownLinkActive};
}

.dropdown-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
