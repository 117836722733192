@import '../../../../../styles/variables';

.expand-dropdown {
  color: $secondary !important;
}

.expand-button {
  border: none;
  color: $secondary !important;
  background: none;
  text-transform: uppercase;
  text-align: left;
  padding: 0;
  margin: 0;
}

.image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
