@import '../../../styles/variables';

.icon-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
  
.icon-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.button-image {
    border: none;
    background-color: yellow;
}

.add-icon-button {
    border: 1px solid $secondary;
    color: black;
    background: none;
    text-align: left;
    padding: 10px;
    margin: 0;  
    border-radius: 8px;
}
  
.active-icon {
    padding: 10px;
    border-radius: 50%;
    background-color: $secondary;
}