@import 'src/styles/variables';

.upload-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc($spacing * 20) calc($spacing * 10);
  border: 3px dashed $grey-light;
  border-radius: calc($spacing * 6);

  &.disabled {
    border-color: lighten($text, 55);
    position: relative;
    background: repeating-linear-gradient(45deg, lighten($text, 55), lighten($text, 55) 10px, lighten($text, 65) 10px, lighten($text, 65) 20px);
    color: lighten($text, 25);
  }
}

.preview-box {
  margin-top: calc($spacing * 8);
  margin-bottom: calc($spacing * 8);

  .card-footer {
    padding: $spacing;
  }
}
