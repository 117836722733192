@import '../../../../styles/variables';

.customer-details {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.customer-search-item {
  padding: $spacing calc($spacing * 4) !important;
  &:hover {
    background-color: $dropdownLinkHover;
  }
  &:active {
    background-color: $dropdownLinkActive;
  }
}
