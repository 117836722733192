$primary: #5bbccc;
$secondary: #ec672b;
//$danger = '';
//$success =;
$bg: #f8f9fd;
$text: #121212;
$white: #ffffff;
$danger: #dc3545;
$warning: #ffc107;
$success: #198754;
$info: #0dcaf0;
$grey: #6c757d;
$dark: #6c757d;
$grey-light: #dee2d6;
$border-color: #dfdfdf;
$table-head-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), $white;
$table-striped-bg: $bg;
$table-hover-bg: $bg;
$input-border-color: $grey;
$dropdownLinkHover: lighten($text, 70);
$dropdownLinkActive: $primary;
$spacing: 5px;
$font-size-base: 0.95rem;
$h6-font-size: 1.1rem;
$h5-font-size: 1.2rem;
$h4-font-size: 1.3rem;
$h3-font-size: 1.4rem;
$h2-font-size: 1.5rem;
$h1-font-size: 1.6rem;
$input-border-radius: 0.375rem;
