@import '../styles/variables';

.btn-danger:hover,
.btn-danger:focus {
  background: lighten($danger, 15) !important;
  border: 1px solid $danger !important;
}

.btn-warning:hover,
.btn-warning:focus {
  background: lighten($warning, 15) !important;
  border: 1px solid $warning !important;
}

.btn-success:hover,
.btn-success:focus {
  background: lighten($success, 15) !important;
  border: 1px solid $success !important;
}

.btn-info:hover,
.btn-info:focus {
  background: lighten($info, 15) !important;
  border: 1px solid $info !important;
}

.btn-icon {
  width: calc($spacing * 6) !important;
  height: calc($spacing * 6) !important;
  padding: $spacing !important;
}

.icon {
  width: calc($spacing * 4) !important;
  height: calc($spacing * 4) !important;
  aspect-ratio: 1/1;
  color: $secondary;
  fill: $secondary;

  &.white {
    color: $white;
    fill: $white;
  }

  &.primary {
    color: $primary;
    fill: $primary;
  }

  &.danger {
    color: $danger;
    fill: $danger;

    &:hover {
      color: $bg;
      fill: $bg;
    }
  }

  &.text {
    color: $text;
    fill: $text;
  }
}

.form-check-input {
  width: calc($spacing * 4) !important;
  height: calc($spacing * 4) !important;
  margin-left: calc($spacing * 3);
  aspect-ratio: 1/1;
}

.form-check-input:checked {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.btn-lg {
  border-radius: calc($spacing * 2);
  padding: calc($spacing * 6) calc($spacing * 3);
}

.btn-md {
  border-radius: calc($spacing * 2);
  padding: calc($spacing * 4) calc($spacing * 2);
}

.btn-sm {
  border-radius: calc($spacing * 2);
  padding: calc($spacing * 2) calc($spacing * 1);
}

.dropdown-toggle,
[class*='btn-'] {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 1px solid;
  column-gap: $spacing;
}

.dropdown-toggle {
  border-color: $grey !important;
}

.collapse-toggle {
  border: none !important;
  border-radius: unset !important;
}

.inner-list {
  padding-left: 30px;
}

.btn-primary:hover {
  background-color: lighten($primary, 25%) !important;
}

.btn-secondary:hover {
  background-color: lighten($secondary, 25%) !important;
}

.btn-dark:hover {
  background-color: lighten($dark, 25%) !important;
}

.btn-primary.dropdown-toggle::after {
  border-top: 0.4em solid $primary !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
}

.btn-secondary.dropdown-toggle::after {
  border-top: 0.4em solid $secondary !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
}

.btn-md:not(.btn-danger, .btn-info, .btn-success, .btn-warning),
.dropdown-toggle {
  &::after {
    color: transparent !important;
  }

  &.checked {
    &.btn-secondary {
      background: $secondary !important;
      color: $bg !important;

      &:disabled {
        background: darken($secondary, 20%) !important;
      }

      &:hover {
        transition: all 0.4s ease;

        &::after {
          color: $text !important;
        }

        .icon {
          transition: all 0.4s ease;
          fill: $text !important;
        }
      }
    }

    &.btn-primary {
      background: $primary !important;
      color: $bg !important;

      &:disabled {
        background: darken($primary, 20%) !important;
      }

      &:hover {
        transition: all 0.4s ease;

        &::after {
          color: $bg !important;
        }

        .icon {
          transition: all 0.4s ease;
          fill: $bg !important;
        }
      }
    }

    &.btn-dark {
      background: $dark !important;
      color: $bg !important;

      &:disabled {
        background: darken($dark, 20%) !important;
      }

      &:hover {
        transition: all 0.4s ease;

        &::after {
          color: $bg !important;
        }

        .icon {
          transition: all 0.4s ease;
          fill: $bg !important;
        }
      }
    }
  }
}

th .dropdown-toggle {
  margin-top: calc($spacing * 2);
}

@mixin caret {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  transition: all 0.4s ease;
}

.caret {
  &.open::after {
    @include caret;
    border-top: 0.3em solid;
    border-bottom: 0;
  }

  &.close::after {
    @include caret;
    border-bottom: 0.3em solid;
    border-top: 0;
  }

  &.btn-secondary::after {
    border-bottom-color: $secondary;
    border-top-color: $secondary;
  }

  &.btn-primary::after {
    border-bottom-color: $primary;
    border-top-color: $primary;
  }
}
