@import '../../styles/variables';

.react-datepicker__day--highlighted,
.react-datepicker__day--highlighted:hover {
  background: $primary !important;
}

.react-datepicker__day--disabled {
  color: rgba($text, 0.45) !important;
}

.react-datepicker__day--outside-month {
  background: none;
  color: rgba($text, 0.15) !important;
  pointer-events: none;
}

.react-datepicker__day {
  font-weight: 400;
}

.react-datepicker__day--today {
  font-weight: 600;
}

.react-datepicker {
  display: flex;
  flex-flow: column nowrap;
  max-width: calc($spacing * 50);
  outline: solid $spacing rgba($text, 0.2) !important;
  z-index: 10;

  & * {
    width: 100%;
  }
}

.react-datepicker-wrapper {
  border-top-right-radius: $input-border-radius !important;
  border-bottom-right-radius: $input-border-radius !important;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;

  &.has-addon {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

.datepicker-notification {
  padding: $spacing calc($spacing * 3) calc($spacing * 2);
}
