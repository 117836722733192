@import '../../styles/variables';

.menu {
  z-index: 30;
  position: relative;
  top: 0;
  left: 0;
  max-width: calc($spacing * 60);
  height: 100vh;
  padding: calc($spacing * 4) calc($spacing * 6);
  background: $bg;
  border-right: 1px solid $primary;
  font-size: $font-size-base;

  &.collapsed {
    max-width: calc($spacing * 12);
    padding: calc($spacing * 4) calc($spacing * 2);
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: calc($spacing * 3);
    padding: calc($spacing * 2) 0;
    color: $text;

    &.icon-link {
      justify-content: center;
    }

    &-text {
      font-weight: 600;
      text-decoration: none;
    }

    &.active, &.active * {
      color: $primary;
      fill: $primary;
    }
  }
}

.menu-block {
  display: flex;
  flex-flow: column nowrap;
}
