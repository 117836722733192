@import 'styles/variables';

.root {
  min-height: 100vh;
  overflow: hidden;
}

.content {
  height: 100vh;
  max-width: none;
  overflow: auto;
  padding: calc($spacing * 4) calc($spacing * 6);
}

.list-header {
  color: $secondary;
  font-weight: 700;
  margin-bottom: calc($spacing * 2);
}

.nav-tabs {
  .nav-link,
  .nav-link:hover {
    color: $secondary;
    cursor: pointer;
  }
}

.modal-title {
  width: 100%;
}

.cursor-auto {
  cursor: auto !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.border-grey {
  border-color: $grey !important;
}

.loader {
  &-sm {
    --bs-spinner-width: 20px !important;
    --bs-spinner-height: 20px !important;
  }
  &-md {
    --bs-spinner-width: 100px !important;
    --bs-spinner-height: 100px !important;
  }
  &-lg {
    --bs-spinner-width: 150px !important;
    --bs-spinner-height: 150px !important;
  }
  &-xl {
    --bs-spinner-width: 150px !important;
    --bs-spinner-height: 150px !important;
  }
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}
