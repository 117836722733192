.thumb-video {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 250px;
    width: 100%;
}
  
.thumb-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
  