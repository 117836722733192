@import '../../../styles/variables';

.table-head {
  font-size: 16px;
  background: $table-head-bg;
}

.flex-cell {
  display: flex;
  flex: 1;
  flex-basis: 50%;
  max-width: 50%;
}

.flex-cell-left {
  flex: 1;
  flex-basis: 30%;
  max-width: 30%;
}

.flex-cell-right {
  flex: 1;
  flex-basis: 70%;
  max-width: 70%;
}

.repair {
  border: 1px solid $border-color;
}

.repair-head {
  background: $table-head-bg;
}

.repair-details {
  border-top: 1px solid $border-color;
}

.repair-status {
  background-color: $grey !important;
}

.product {
  width: 45%;
}

.text {
  white-space: normal;
  word-wrap: break-word;
}

.stars-rating {
  width: 20px;
  height: 20px;
  color: #ee7640;
}

.stars-container {
  display: flex;
  height: 27px;
}
